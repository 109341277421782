'use strict';

import $ from 'jquery';

const CONTAINER_CLASS = 'autoresize__container';
const WIDTH_CLASS = 'autoresize--width';
const HEIGHT_CLASS = 'autoresize--height';

var instance;

// Constructor
var Autoresize = function() {

  // === Singleton ===
  if (instance) {
    return instance;
  } else {
    instance = this;
  }

  var $win = $(window);
  var registeredTargets = [];

  function windowAR() {
    return $win.width() / $win.height();
  }

  function setClass (target) {
    if (target.ARLargerThanWindow) {
      target.$elem.removeClass(WIDTH_CLASS).addClass(HEIGHT_CLASS);
    } else {
      target.$elem.removeClass(HEIGHT_CLASS).addClass(WIDTH_CLASS);
    }
  }

  $win.resize(() => {
    var winAR = windowAR();
    var currentTarget;
    for (var i = registeredTargets.length - 1; i >= 0; i--) {
      currentTarget = registeredTargets[i];
      if (currentTarget.ARLargerThanWindow !== (currentTarget.AR > winAR)) {
        currentTarget.ARLargerThanWindow = currentTarget.AR > winAR;
        setClass(currentTarget);
      }
    }
  });

  this.add = function ($elem, width, height) {
    var target = {
      $elem: $elem,
      AR: (width || $elem.width()) / (height || $elem.height())
    };
    target.ARLargerThanWindow = target.AR > windowAR();
    $elem.parent().addClass(CONTAINER_CLASS);
    setClass(target);
    registeredTargets.push(target);
  };
};

module.exports = Autoresize;
