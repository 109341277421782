import $ from 'jquery';

function initSmoothAnchorScrolling(paramName) {
  $(document).on('click', 'a[href*="#"]', function (event) {
        let targetSelector = '#' + $(this).attr('href').split('#')[1]
        let $target = $(targetSelector)

        if ($target.length === 0) {
            return
        }

        event.preventDefault();

        $('html, body').animate({
            scrollTop: $target.offset().top
        }, 500);
    });
}

module.exports = initSmoothAnchorScrolling;