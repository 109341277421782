'use strict';

import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Isotope from 'isotope-layout';
import hashnav from '../../_scripts/hashnav.js';

jQueryBridget( 'isotope', Isotope, $ ); // make Isotope a jQuery plugin


export default class Tiles {
  constructor() {
    var $container = $('.tiles__tile-container');
    var $filters = $('.tiles__filter');
    var $dropdown = $('.tiles__filter-dropdown');
    var filterActiveClass = 'tiles__filter--active';
    $container.isotope();

    // How the object name is generated from the filter selector
    function filterSelectorToName(filterSelector) {
      return filterSelector.replace('.', '');
    }

    // Get a list of the filters available
    var availableFilters = {};
    $filters.each(function() {
      var selector = $(this).data('filter');
      if (typeof selector == "undefined") selector = "";
      var name = filterSelectorToName(selector);

      if (selector && name) {
        availableFilters[name] = {
          selector: selector,
          element: $(this)
        };
      }
    });

    // Apply a filter
    function filter(filterArgument) {

      var filterObject;
      switch(typeof filterArgument) {
      case 'string':
        filterObject = availableFilters[filterArgument];
        break;
      case 'object':
        filterObject = filterArgument;
        break;
      default:
        console.warn('Unsupport filterArgument type!');
      }


      $filters.removeClass(filterActiveClass);
      filterObject.element.addClass(filterActiveClass);
      $container.isotope({filter: filterObject.selector});
    }

    // Apply filter by clicking
    $filters.click(function() {
      filter(filterSelectorToName($(this).data('filter')));
    });

    // Apply filter via dropdown
    $dropdown.change(function(event) {
      filter($(this).val());
    });

    // Apply filter by hashnav change
    hashnav(function(hash) {
      if (hash.base in availableFilters) {
        filter(hash.base);
      }
    });
  }
}
