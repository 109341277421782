'use strict';

import $ from 'jquery';

var instance;

// Are passive event handlers supported?
var supportsPassive = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function() {
      supportsPassive = true;
    }
  });
  window.addEventListener("test", null, opts);
} catch (e) {}

// Constructor
var Nav = function() {
  var $nav = $('.nav');
  var open = false;

  // === Singleton ===
  if (instance) {
    return instance;
  } else {
    instance = this;
  }

  // === Menu ===
  function toggleNav() {
    open = !open;
    $nav.toggleClass('nav--closed', !open);
  }
  $('.nav__menu-toggle').click(toggleNav);

  // === Fixed Scrolling ===
  var $win = $(window);
  var atTop = true;
  var classToAddWhenScrolled = 'nav--scrolled';

  function checkScroll() {
    if ( ($win.scrollTop() === 0) !== atTop) {
      atTop = !atTop;
      if (atTop) {
        // Currently at the top of the page
        $nav.removeClass(classToAddWhenScrolled);
      } else {
        // Currently NOT at the top of the page
        $nav.addClass(classToAddWhenScrolled);
      }
    }
    if (open) {
      // close nav when scrolling
      toggleNav();
    }
  }
  checkScroll();
  window.addEventListener('scroll', checkScroll, supportsPassive ? { passive: true } : false);

  // === Inveted Mode ===
  this.setInverted = (inverted, first) => {
    $nav.toggleClass('nav--inverted', !!inverted);
  };
};

module.exports = Nav;
