'use strict';

import $ from 'jquery';
import 'slick-carousel';

// Constructor
var AlexaSlider = function() {

  var $slider = $(".alexa-slider-container");
  // Has Slider
  if ($slider.length > 0) {
    var $nav = $(".alexa-swiper-nav > .alexa-swiper-dot");
    var prevIndex = 0;

    $nav.on("click", function() {
      $slider.slick("slickGoTo", $(this).index());
    });

    function setActiveIndex(index) {
      $nav.eq(prevIndex).removeClass("active");
      $nav.eq(index).addClass("active");
      prevIndex = index;
    }

    // === Slider Functions ===
    function onSlideLeave(event, slick, currentIndex, nextIndex) {
      setActiveIndex(nextIndex);
    }

    function onInit(event, slick) {
      setActiveIndex(slick.currentSlide);
    }

    $slider
      // Register Events
      .on("init", onInit)
      .on("beforeChange", onSlideLeave)
      // Init Slick
      .slick({
        accessibility: false,
        dots: false,
        arrows: false,
        adaptiveHeight: false,
        initialSlide: 0,
        infinite: false
      });

  }
};

module.exports = AlexaSlider;
