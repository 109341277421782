import $ from 'jquery';

// Import Foundation Core
import { Foundation } from 'foundation-sites/js/foundation.core';
Foundation.addToJquery($);

// Import 'Triggers' Utility for resizeme events
import { Triggers } from 'foundation-sites/js/foundation.util.triggers';
Triggers.init($, Foundation);

// Import 'Interchange' Plugin for responsive content interchanging
import { Interchange } from 'foundation-sites/js/foundation.interchange';
Foundation.plugin(Interchange, 'Interchange');

// Define some media queries for easier usage
Foundation.Interchange.SPECIAL_QUERIES['default'] = 'screen and (min-width: 0)';

Foundation.Interchange.SPECIAL_QUERIES['small'] = 'screen and (max-width: 640px)';
Foundation.Interchange.SPECIAL_QUERIES['medium'] = 'screen and (min-width: 641px) and (max-width: 1024px)';
Foundation.Interchange.SPECIAL_QUERIES['large'] = 'screen and (min-width: 1025px) and (max-width: 1280px)';
Foundation.Interchange.SPECIAL_QUERIES['xlarge'] = 'screen and (min-width: 1281px)';

Foundation.Interchange.SPECIAL_QUERIES['medium-up'] = 'screen and (min-width: 641px)';
Foundation.Interchange.SPECIAL_QUERIES['large-up'] = 'screen and (min-width: 1025px)';

$(document).foundation();